'use strict';
function menuPaymentsReportCtrl($scope, user, paymentsReportService) {
  $scope.paymentsReportService = paymentsReportService;
  $scope.user = user;

}

'use strict';

/* exported paymentsReportCtrl */
function paymentsReportCtrl($rootScope, $filter, $scope, user, InventoryRestangular, UsersRestangular, paymentsReportService, company, BookingViewModal, $location, $uibModal) {
  $scope.isInvoicesFetchStarted = false;
  $scope.paymentsReportService = paymentsReportService;
  $scope.BookingViewModal = BookingViewModal;
  $scope.location = $location;
  $scope.user = user;
  $scope.isFinanceAdmin = user.additionalScopes.includes('finance-admin');
  $scope.allowDepositRequest = $scope.isFinanceAdmin || user.additionalScopes.includes('allow-deposit-request');
  $scope.isCCO = user.additionalScopes.includes('cco');
  $scope.isSupplyManager = user.additionalScopes.includes('supply-manager');
  $scope.isFinanceOps = user.additionalScopes.includes('finance-ops');
  $scope.showInvoiceGapsReport = user.additionalScopes.includes('invoice-gaps-report');
  $scope.hasSpecialApprovalPermission = user.additionalScopes.includes('invoice-approve-gap');
  if(!user.shouldSeeFullAdmin && company) {
    $scope.selectedSupplier = company;
  }

  $scope.overviewInvoicePageChanged = function() {
    const page = paymentsReportService.overviewInvoiceCurrPage;
    const startIndex = ((page - 1) * $scope.filters.overview.pageSize);
    let endIndex = startIndex + $scope.filters.overview.pageSize;
    if (endIndex > $scope.filters.overview.filteredInvoiceData.length) {
      endIndex = $scope.filters.overview.filteredInvoiceData.length;
    }
    $scope.currOverviewInvoicePageItems = $scope.filters.overview.filteredInvoiceData.slice(startIndex, endIndex);
  };

  $scope.invoiceStatusData = [
    { label: 'Open', id: 'open' },
    { label: 'Awaiting Approval', id: 'awaiting_approval' },
    { label: 'Approved', id: 'invoice_approved' },
    { label: 'Paid', id: 'paid' },
    { label: 'Rejected', id: 'invoice_rejected' },
    { label: 'Payment canceled', id: 'payment_canceled' },
  ];

  $scope.settings = {
    externalIdProp: '',
  };

  var currDate = new Date();
  $scope.isLoading = false;
  $scope.accruedReport = [];
  $scope.accruedExpectedTotal = 0;
  $scope.filteredAccruedReport = [];
  $scope.isInvoiceReportLoading = true;
  $scope.invoicePeriods = [];
  $scope.activeTab = user.shouldSeeFullAdmin ? 0 : 1;
  $scope.supplierList=  [];
  $scope.reportData = null;
  $scope.invoiceFileForUpload = null;
  $scope.invoicePaymentCycleIdForFileUpload = null;
  $scope.reportTotals = [];
  $scope.currPageItems = [];
  $scope.currOverviewInvoicePageItems = [];
  $scope.currInvoiceReportPageItems=[];
  $scope.currTopupReportPageItems=[];
  $scope.invoiceReportData = [];
  $scope.supplierEOMReport = null;
  $scope.supplierTopupReport = null;
  $scope.totalItems = 0;
  $scope.user = user;
  $scope.overviewReport = null;
  $scope.filters = {
    invoiceGapsReport: {
      supplier: '',
      fromDate: moment().startOf('year').toDate(),
      toDate: moment().toDate(),
    },
    accruedReport: {
        supplier: '',
        fromDate: moment().subtract(1, 'month').startOf('month').toDate(),
        toDate: moment().subtract(1, 'month').endOf('month').toDate(),
        selectedSupplier: user.shouldSeeFullAdmin ? null : user.company,
        limit: 30,
      },
      overview: {
        paymentType: 'topup',
        name: '',
        addressCountry: '',
        filteredTopupData: [],
        filteredInvoiceData: [],
        owner: '',
        maxBookingDate: null,
        region: null,
        pageSize: 50,
      },
      paymentReport: {
        fromDate: new Date(currDate.getFullYear(), currDate.getMonth(), 1),
        toDate: new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0),
        pageSize: 50,
        bookingReference: '',
        selectedSupplier: user.shouldSeeFullAdmin ? null : user.company,
        searchBy: 'dates',
      },
      invoiceReport: {
        filteredInvoiceReportData: [],
        pageSize: 50,
        showMyInvoices: true,
        selectedRegion: { label: 'All', value: null },
        regions: [
          { label: 'All', value: null },
          { label: 'latam', value: 'latam' },
          { label: 'apac', value: 'apac' },
          { label: 'emea', value: 'emea' },
          { label: 'north_america', value: 'north_america' },],
        selectedStatus: $scope.invoiceStatusData.filter(status => status.id !== 'invoice_rejected'),
        selectedGapLevel: { label: 'All', value: null },
        gapLevelOptions: [
          { label: 'All', value: null, },
          { label: 'Level 1', value: 1 },
          { label: 'Level 2', value: 2 },
          { label: 'Level 3', value: 3 },
        ],
        statuses: $scope.invoiceStatusData,
        selectedAssignee: [],
        assignees: [],
        sortOption: { label: 'Due Date, latest first', value: 'dueDateLatest' },
        sortOptions: [
          { label: 'Due Date, earlier first', value: 'dueDate' },
          { label: 'Due Date, latest first', value: 'dueDateLatest' },
        ],
        selectedSupplier: null,
        months: [],
        selectedMonths: [],
      },
      supplierSpecificReport: {
        selectedSupplier: null,
        showBy: 'effective_date',
        showMoreTopupItems: true,
        filteredSupplierTopupData: [],
        topupOperation: '',
        topupPageSize: 100,
      }
  };

  if (user.shouldSeeFullAdmin) {
    ($scope.fetchOverviewReport = function () {
      var overviewUrl = 'payments-overview-report?useCache=true';
      if ($scope.filters.overview.maxBookingDate) {
        if (!$scope.isLastDayOfMonth($scope.filters.overview.maxBookingDate)) {
          return $rootScope.$emit('notify', {type: 'error', title: 'Invalid date selection', message: 'Please select only last day of month or clear the date input.'});
        }
        overviewUrl += '&maxBookingDate=' + moment($scope.filters.overview.maxBookingDate).format("YYYY-MM-DD");
      }
      $scope.overviewReport = null;
      InventoryRestangular.one('v2/supplier').all(overviewUrl).get('').then(function (overviewReport) {
        $scope.overviewReport = overviewReport.data;
        $scope.filters.overview.filteredTopupData = overviewReport.data.topup;
        $scope.filters.overview.filteredInvoiceData = overviewReport.data.invoice;
        $scope.filters.overview.lastUpdatedAt = overviewReport.data.lastUpdatedAt ? moment(overviewReport.data.lastUpdatedAt).format("YYYY-MM-DD HH:mm"): '';
        const paymentCyclesSet = new Set(['All']);
        const periodSet = new Set(['All']);
        const invoiceStatusesFilter = new Set(['All']);
          overviewReport.data.invoice.forEach(function (row) {
            paymentCyclesSet.add(row.invoicePaymentCycle);
            periodSet.add(row.period);
            invoiceStatusesFilter.add(row.status);
          });
        $scope.paymentCycleForFilter = Array.from(paymentCyclesSet);
        $scope.periodForFilter = Array.from(periodSet);
        $scope.invoiceStatusesFilter = Array.from(invoiceStatusesFilter);
        $scope.overviewInvoicePageChanged();
      });
    })();
  }

  $scope.regions = [
    { label: 'All', value: 'all' },
    { label: 'Latin America', value: 'latam' },
    { label: 'Asia Pacific', value: 'apac' },
    { label: 'Europe, the Middle East and Africa', value: 'emea' },
    { label: 'North America', value: 'north_america' },
  ];
  $scope.$watch('filters.overview.region', function (region) {
    $scope.overviewFilterChange();
  });
  $scope.$watch('[filters.overview.selectedPaymentCycle, filters.overview.selectedPeriod, filters.overview.region, filters.overview.selectedInvoiceStatus]', function () {
    $scope.overviewFilterChange();
  });


  $scope.$watch('filters.supplierSpecificReport.selectedSupplier', function () {
    $scope.filters.supplierSpecificReport.topupOperation = '';
    $scope.filters.supplierSpecificReport.filteredSupplierTopupData = [];
    if($scope.filters.supplierSpecificReport.selectedSupplier){
      if ($scope.filters.supplierSpecificReport.selectedSupplier.paymentType === 'topup') {
        $scope.supplierTopupReport = null;
        $scope.filters.supplierSpecificReport.showMoreTopupItems = true;
        $scope.filters.supplierSpecificReport.supplierTopupReportByBookingDate = [];
        $scope.filters.supplierSpecificReport.supplierTopupReportByEffectiveDate = [];
        $scope.fetchSupplierTopupReport($scope.filters.supplierSpecificReport.selectedSupplier._id, true);
      }
      if ($scope.filters.supplierSpecificReport.selectedSupplier.paymentType === 'invoice') {
        $scope.fetchSupplierInvoiceEOMReport(
          $scope.filters.supplierSpecificReport.selectedSupplier._id,
          $scope.filters.supplierSpecificReport.selectedSupplier.invoicePaymentCycle
        );
      }
    }
  }, true);

  $scope.showMyInvoices = function(value) {
    $scope.filters.invoiceReport.showMyInvoices = value;
    $scope.invoiceReportFilterChange();
  };
  $scope.changeInvoiceReportSortOrder = function() {
    if ($scope.invoiceReportData && $scope.invoiceReportData.length > 0) {
      $scope.invoiceReportData = $scope.invoiceReportData.reverse().sort(function(a, b) {
        if (b.status === 'awaiting_approval') { return 1; }
        return -1;
      });
      $scope.invoiceReportFilterChange();
    }
  };
  $scope.invoiceReportFilterChange = function(e) {
    if ($scope.invoiceReportData) {
      $scope.filters.invoiceReport.filteredInvoiceReportData = $scope.invoiceReportData.filter((invoice) => {
        let res = true;
        if (res && $scope.filters.invoiceReport.showMyInvoices) {
          res = invoice.assignee && invoice.assignee.userId === user._id && invoice.status !== 'invoice_approved';
        }
        return res;
      });
      $scope.invoiceReportPageChanged();
    }
  };
  $scope.overviewFilterChange = function () {
    if ($scope.overviewReport) {
      const filteredReportName = $scope.filters.overview.paymentType === 'invoice' ? 'filteredInvoiceData' : 'filteredTopupData';
      const report = $scope.filters.overview.paymentType === 'invoice' ? $scope.overviewReport.invoice : $scope.overviewReport.topup;
      $scope.filters.overview[filteredReportName] = report.filter(item => {
        let res = true;
        if ($scope.filters.overview.name && $scope.filters.overview.name.length > 0) {
          res = item.supplier.name.toLowerCase().includes($scope.filters.overview.name.toLowerCase());
        }
        if (res && $scope.filters.overview.addressCountry.length > 0) {
          res = item.supplier.addressCountry.toLowerCase().includes($scope.filters.overview.addressCountry.toLowerCase());
        }
        if (res && $scope.filters.overview.owner && $scope.filters.overview.owner.length > 0) {
          res = item.supplier.operationsOwner.toLowerCase().includes($scope.filters.overview.owner.toLowerCase());
        }
        if (res && $scope.filters.overview.region && $scope.filters.overview.region.value && $scope.filters.overview.region.value !== 'all') {
          res = item.supplier.region.toLowerCase() === $scope.filters.overview.region.value;
        }
        if ($scope.filters.overview.paymentType === 'invoice') {
          if (res && $scope.filters.overview.selectedPaymentCycle && $scope.filters.overview.selectedPaymentCycle !== 'All') {
            res = item.invoicePaymentCycle && item.invoicePaymentCycle.toLowerCase().includes($scope.filters.overview.selectedPaymentCycle.toLowerCase());
          }
          if (res && $scope.filters.overview.selectedPeriod && $scope.filters.overview.selectedPeriod !== 'All') {
            res = item.period && item.period.toLowerCase().includes($scope.filters.overview.selectedPeriod.toLowerCase());
          }
        }
        if (res && $scope.filters.overview.selectedInvoiceStatus && $scope.filters.overview.selectedInvoiceStatus !== 'All') {
          res = item.status && item.status === $scope.filters.overview.selectedInvoiceStatus;
        }
        return res;
      });
      if ($scope.filters.overview.paymentType === 'invoice' && user.shouldSeeFullAdmin) {
        $scope.overviewInvoicePageChanged();
      }
    }
  };

  $scope.$watch('invoiceFileForUpload', function () {
    if($scope.invoicePaymentCycleIdForFileUpload){
      console.log("uploading invoice ", $scope.invoiceFileForUpload);
    }
  }, true);
  $scope.invoiceFileSelected = function (invoiceId, event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("invoiceId", invoiceId);
    console.log(invoiceId);
  };
  $scope.setActiveTab = function(index) {
    $scope.activeTab = index;
    paymentsReportService.activeTab = index;
  };

  $scope.$watch('filters.supplierSpecificReport.topupOperation', function (newVal) {
    if ($scope.supplierTopupReport) {
      $scope.filters.supplierSpecificReport.filteredSupplierTopupData = [];
      if(newVal.length === 0) {
        $scope.renderTopupReportInChunks(0, 100);
      } else {
        $scope.filters.supplierSpecificReport.filteredSupplierTopupData = $scope.supplierTopupReport.topups.filter((item) => item.type === newVal);
      }
      $scope.topupReportPageChanged();
    }
  });


  $scope.downloadAccruedForPastYear = function() {
    InventoryRestangular.one('v2/supplier')
    .all(`accrued-report-for-past-year`).get('')
    .then(function(csvString) {
      const toDate = moment().subtract(1, 'months').endOf('month');
      const fromDate = toDate.clone().subtract(1, 'years').add(1, 'months').startOf('month');
      const fileName = `accrued-report-${fromDate.format('YYYY-MM-DD')}-${toDate.format('YYYY-MM-DD')}.csv`;
      const blob = new Blob([csvString], { type: 'text/csv' }); // Convert to Blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };

  $scope.fetchAccruedReport = function(skip) {
    $scope.fetchingAccruedReport = true;
    const fromDate = moment($scope.filters.accruedReport.fromDate).startOf('day').format('YYYY-MM-DD');
    const toDate = moment($scope.filters.accruedReport.toDate).endOf('day').format('YYYY-MM-DD');
    if (!skip) {
      skip = 0;
    }
    if (skip === 0) {
      $scope.accruedReport = [];
      $scope.filteredAccruedReport = [];
    }
    InventoryRestangular.one('v2/supplier')
    .all(`eom-accrued-report?fromDate=${fromDate}&toDate=${toDate}&skip=${skip}&limit=${$scope.filters.accruedReport.limit}`).get('')
    .then(function(accruedReport) {
      $scope.accruedExpectedTotal = accruedReport.totalDocs === 0 ? $scope.accruedExpectedTotal : accruedReport.totalDocs;
      $scope.accruedReport = $scope.accruedReport.concat(accruedReport.items);
      $scope.filteredAccruedReport = $scope.filteredAccruedReport.concat(accruedReport.items);
      if ($scope.accruedExpectedTotal > (skip + $scope.filters.accruedReport.limit)) {
        $scope.fetchAccruedReport(skip + $scope.filters.accruedReport.limit);
      } else {
        $scope.fetchingAccruedReport = false;
      }
    }).catch(function(err) {
      $rootScope.$emit('notify', {type: 'error', title: '', message: 'Error fetching the accrued report.' });
      $scope.fetchingAccruedReport = false;
    });
  }

$scope.renderTopupReportInChunks = function(startIndex, chunkSize) {
  const reportData = $scope.supplierTopupReport.report;
  const endIndex = Math.min(startIndex + chunkSize, reportData.length);
  const chunk = reportData.slice(startIndex, endIndex);
  $scope.filters.supplierSpecificReport.filteredSupplierTopupData.push(...chunk);
  $scope.$applyAsync(); // Ensure UI updates

  if (endIndex < reportData.length) {
    // Process the next chunk after a short delay
    setTimeout(() => $scope.renderTopupReportInChunks(endIndex, chunkSize), 200);
  }
}

$scope.fetchSupplierTopupReport = function (supplierId, newSupplierSearch) {
  $scope.fetchingMoreTopups = true;
  if (newSupplierSearch) {
    $scope.filters.supplierSpecificReport.showBy = 'effective_date';
  }
  const forceByBookingDate = $scope.filters.supplierSpecificReport.showBy === 'booking_date';
  
  InventoryRestangular.one('v2/supplier')
    .all(`${supplierId}/topup?forceByBookingDate=${forceByBookingDate}`).get('')
    .then(function (supplierTopupReport) {
      if (supplierTopupReport.header.lastTopupAdjustment) {
        supplierTopupReport.header.lastTopupAdjustment = moment
          .utc(supplierTopupReport.header.lastTopupAdjustment.date)
          .format('DD/MM/YYYY');
      }
      $scope.supplierTopupReport = supplierTopupReport;

      $scope.filters.supplierSpecificReport.filteredSupplierTopupData = [];
      // Start processing chunks
      $scope.renderTopupReportInChunks(0, 100); // Render 100 items at a time

      // Store data by showBy filter
      if ($scope.filters.supplierSpecificReport.showBy === 'booking_date') {
        $scope.filters.supplierSpecificReport.supplierTopupReportByBookingDate = supplierTopupReport;
      } else {
        $scope.filters.supplierSpecificReport.supplierTopupReportByEffectiveDate = supplierTopupReport;
      }
      $scope.topupReportPageChanged();
    })
    .catch(function () {
      return $rootScope.$emit('notify', {
        type: 'error',
        title: '',
        message: 'Error fetching supplier topup report.',
      });
    })
    .finally(function () {
      $scope.fetchingMoreTopups = false;
    });
};


  $scope.changeTopupShowBy = function(showBy) {
    $scope.fetchingMoreTopups = true;
    try {
      $scope.filters.supplierSpecificReport.showBy = showBy;
      const reportByBookingDate = $scope.filters.supplierSpecificReport.supplierTopupReportByBookingDate;
      const reportByEffectiveDate = $scope.filters.supplierSpecificReport.supplierTopupReportByEffectiveDate;
      if (showBy === 'booking_date' && reportByBookingDate && reportByBookingDate.report && reportByBookingDate.report.length > 0) {
        $scope.supplierTopupReport = reportByBookingDate;
        $scope.filters.supplierSpecificReport.filteredSupplierTopupData = [];
        $scope.renderTopupReportInChunks(0, 100);
      } else if(showBy === 'effective_date' && reportByEffectiveDate && reportByEffectiveDate.report && reportByEffectiveDate.report.length > 0) {
        $scope.supplierTopupReport = reportByEffectiveDate;
        $scope.filters.supplierSpecificReport.filteredSupplierTopupData = [];
        $scope.renderTopupReportInChunks(0, 100);
      } else {
        $scope.filters.supplierSpecificReport.filteredSupplierTopupData = [];
        $scope.fetchSupplierTopupReport($scope.filters.supplierSpecificReport.selectedSupplier._id);
      }
      $scope.topupReportPageChanged();
    } catch(err) {
      $scope.fetchingMoreTopups = false;
      return $rootScope.$emit('notify', {type: 'error', title: '', message: 'Error fetching supplier topup report.' });
    }
  }

  $scope.fetchSupplierInvoiceEOMReport = function(supplierId, invoicePaymentCycle) {
    return InventoryRestangular.one('v2/supplier')
    .all(`${supplierId}/invoices?invoicePaymentCycle=${invoicePaymentCycle}`).get('')
    .then(function(supplierInvoicesReport) {
      $scope.invoicePeriods = [];
      const invoices = supplierInvoicesReport.allSupplierInvoices.map((invoice) => {
        let period = moment(invoice.startDate).format('MMMM YYYY');
        if (invoice.invoicePaymentCycle === 'bi_weekly') {
          period = `${moment.utc(invoice.startDate).date()}-${moment.utc(invoice.endDate).date()} ${moment.utc(invoice.startDate).format('MMM')} ${moment.utc(invoice.endDate).format('YYYY')}`;
        }
        if (moment(invoice.endDate).isBefore(moment())) {
          $scope.invoicePeriods.push({
            start: invoice.startDate,
            end: invoice.endDate,
            label: period + '(' + invoice.currency + ')',
            currency: invoice.currency,
          });
        }
        if(invoice.invoices && invoice.invoices.length > 0) {
          invoice.invoiceNumber = invoice.invoices
            .map((i) => i.invoiceNumber)
            .toString();
          invoice.modifiedBy = invoice.invoices
            .map((i) => i.modifiedBy)
            .toString();

          const paygates = invoice.invoices.map((i) => {
            if (_.get(i, 'paidData.data.paymentMethod') === "payment_gateway") {
              return _.get(i, 'paidData.data.paymentPlatformName');
            }
          }).filter(invoice => !!invoice);
          invoice.paygate = paygates.length ? paygates.join(', ') : "-";
        }
        let extInvoice = {};
        Object.assign(extInvoice, invoice);
        const notes = invoice.invoices.map(i => `${i.note? i.note : ''}  `).toString();
        extInvoice.period = period;
        extInvoice.notes = notes;
        return extInvoice;
      });
      let supplierEOMReport = {};
      Object.assign(supplierEOMReport, supplierInvoicesReport);
      supplierEOMReport.allSupplierInvoices = invoices;
      let nextCycle = {};
      Object.assign(nextCycle, supplierInvoicesReport.nextCycle);
      nextCycle.startDate = moment(supplierInvoicesReport.nextCycle.startDate).format('DD/MM/YYYY');
      nextCycle.endDate = moment(supplierInvoicesReport.nextCycle.endDate).format('DD/MM/YYYY');
      supplierEOMReport.nextCycle = nextCycle;
      $scope.supplierEOMReport = supplierEOMReport;
    });
  };

  $scope.isLastDayOfMonth = function(date){
    if (!date) return false;
    let nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.getDate() === 1;
  }

  $scope.showSupplierEOMReport = function(supplier) {
    $scope.filters.supplierSpecificReport.selectedSupplier = supplier;
    $scope.activeTab = 2;
    paymentsReportService.activeTab = 3;
  };
  function validatePaymentsFromCSV(parsedCSVObj) {
      let isValid = true;
      const errorMessages = [];

      const requiredFields = ['internalId', 'supplierName', 'supplierId', 'invoiceId', 'invoiceNumber', 'paymentCurrency', 'paymentMethod', 'paymentPlatformName','paymentDate', 'paymentReference', 'proofOfPaymentUrl', 'paymentAmount', 'category'];
      const fields = _.get(parsedCSVObj, 'meta.fields', []);
      if (!_.isEqual(fields, requiredFields)){
        errorMessages.push('File does not have all required columns');
      }

      const rows = _.get(parsedCSVObj, 'data', []);
      rows.forEach(function (row, index){
            row.paymentAmount = parseFloat(row.paymentAmount || 0);
            row.invoiceNumber = (row.invoiceNumber || '').replace('Bill #', '');
            if (row.paymentDate){
                if (row.paymentDate.match(/^\d{2}\/\d{2}\/\d{4}$/)){
                    row.paymentDate = moment(row.paymentDate, 'DD/MM/YYYY').format("YYYY-MM-DD");
                }
            }
          if (!row.supplierId) {
              errorMessages.push('line ' + index + ': missing supplier_id');
          }
          else if (!row.invoiceId && !row.invoiceNumber) {
              errorMessages.push('line ' + index + ': missing invoice_id or invoice_number (one of them is required)');
          }
          else if (!row.paymentCurrency) {
              errorMessages.push('line ' + index + ': missing payment_currency');
          }
          else if (!row.paymentAmount && row.paymentAmount !== 0) {
              errorMessages.push('line ' + index + ': missing payment_amount');
          }
          else if ((row.paymentMethod && row.paymentMethod !== "credit_card" && row.paymentMethod !== "cash" ) && !row.paymentPlatformName) {
              errorMessages.push('line ' + index + ': missing payment_platform_name');
          }
          else if (!row.paymentDate) {
              errorMessages.push('line ' + index + ': missing payment_date');
          }
          else if (!row.paymentDate.match(/\d{4}-\d{2}-\d{2}/)) {
              errorMessages.push('line ' + index + ': payment_date must be in the format of : YYYY-MM-DD');
          }
          else if (!moment(row.paymentDate).isValid()) {
              errorMessages.push('line ' + index + ': payment_date has invalid date format');
          }
          else if (moment(row.paymentDate).isAfter(moment())) {
              errorMessages.push('line ' + index + ': payment_date cannot be in the future');
          }
      });

    if (errorMessages.length > 0) {
        isValid = false;

        $uibModal.open({
            templateUrl: 'views/error-messages.html',
            controller: 'ErrorMessagesCtrl',
            resolve: {
                errorMessages: function () {
                    return errorMessages;
                }
            }
        });
    }
        return { isValid: isValid, rows };
  }

  $scope.accruedFilterChange = function() {
    if ($scope.filters.accruedReport.supplier.length === 0) {
      $scope.filteredAccruedReport = $scope.accruedReport;
    } else {
      $scope.filteredAccruedReport = $scope.accruedReport.filter(function(item) {
        return item.supplierName.toLowerCase().indexOf($scope.filters.accruedReport.supplier.toLowerCase()) > -1
      });
    }
  }
  $scope.invoiceGapsFilterChange = function() {
    if ($scope.filters.invoiceGapsReport.supplier.length === 0) {
      $scope.invoiceGapsReport = $scope.invoiceGapsReport;
    } else {
      $scope.filteredInvoiceGapsReport = $scope.invoiceGapsReport.filter(function(item) {
        return item.supplierName.toLowerCase().indexOf($scope.filters.invoiceGapsReport.supplier.toLowerCase()) > -1
      });
    }
  }
  
  $scope.createCSVReport = function(mapper, report, reportName) {
    const replacer = function(key, value) { return value === null ? '' : value; };
  
    let csv = report.map(function(row) {
      return mapper.map(function(field) {
        const indexOfFilter = field.value.indexOf('|');
        if (indexOfFilter > -1) {
          const fieldName = field.value.substring(0, indexOfFilter).trim();
          const filterName = field.value.substring(indexOfFilter + 1).trim();
          return JSON.stringify($filter(filterName)(row[fieldName]), replacer);
        }
        return JSON.stringify(row[field.value], replacer);
      }).join(',');
    });
  
    const mapperRow = mapper.map(function(h) { return h.displayText; });
    csv.unshift(mapperRow.join(',')); // add header column
    csv = csv.join('\r\n');
  
    // Create a new Blob object using the text provided
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
    // Create a link element
    const link = document.createElement('a');
    if (link.download !== undefined) { // Feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', reportName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
$scope.exportSupplierReportToCsv = function() {
  let report;
  let mapper;
  let reportType;
  if ($scope.filters.supplierSpecificReport.selectedSupplier.paymentType === 'topup') {
    reportType = 'topup';
    report = $scope.supplierTopupReport.report;
    mapper = [
      { displayText: 'Date', value: 'date'},
      { displayText: 'Timestamp', value: 'timestamp'},
      { displayText: 'Booking reference', value: 'bookingReference'},
      { displayText: 'Operation', value: 'type' },
      { displayText: 'Amount', value: 'amount|priceFormatToFixed'},
      { displayText: 'Balance', value: 'balance|priceFormatToFixed'},
      { displayText: 'Amount (USD)', value: 'amountUsd|priceFormatToFixed'},
      { displayText: 'Balance(USD)', value: 'balanceUsd|priceFormatToFixed'},
      { displayText: 'Comment', value: 'comment'},
      { displayText: 'Invoice', value: 'invoiceReference'},
      { displayText: 'Modified By', value: 'modifiedBy'},
    ];
  }
  if ($scope.filters.supplierSpecificReport.selectedSupplier.paymentType === 'invoice') {
    reportType = 'invoice';
    report = $scope.supplierEOMReport.allSupplierInvoices;
    mapper = [
      { displayText: 'Period', value: 'period'},
      { displayText: 'Amount (Bookaway)', value: 'totalCost|priceFormatToFixed'},
      { displayText: 'Amount In USD (Bookaway)', value: 'totalCostInUsd|priceFormatToFixed'},
      { displayText: 'Status', value: 'status'},
      { displayText: 'Invoice No.', value: 'invoiceNumber'},
      { displayText: 'Invoice Amount', value: 'invoicesAmount|priceFormatToFixed'},
      { displayText: 'Invoice Modified By', value: 'modifiedBy'},
      { displayText: 'Note', value: 'notes'},
    ];
  }

  return $scope.createCSVReport(mapper, report, `supplier ${reportType} report - ${$scope.filters.supplierSpecificReport.selectedSupplier.name}.csv`);
};

function generateTopupOverviewCSV(){
    if ($scope.overviewReport && $scope.overviewReport.topup && $scope.overviewReport.topup.length) {
    const mapper = [
      {displayText: 'Supplier Name', value: 'supplierName'},
      {displayText: 'Country', value: 'country'},
      {displayText: 'Balance', value: 'balance'},
      {displayText: 'Currency', value: 'currency'},
      {displayText: 'Balance (USD)', value: 'balanceInUsd'},
      {displayText: 'Balance by booking date', value: 'balanceByBookingDate'},
      {displayText: 'Balance by booking date (USD)', value: 'balanceByBookingDateUsd'},
      {displayText: 'Avg. Daily Cost (14 days)', value: 'avgDailyCost'},
      {displayText: 'Days left', value: 'daysLeft'},
    ];
    const date = moment.utc().format('DD-MM-YYYY');
    return $scope.createCSVReport(mapper, $scope.overviewReport.topup, `overview-topup-${date}.csv`);
  } else {
    return $rootScope.$emit('notify', {type: 'warn', title: '', message: 'No Data' });
  }
}

function generateInvoiceOverviewCSV(){
    if ($scope.overviewReport && $scope.overviewReport.invoice && $scope.overviewReport.invoice.length) {
      $scope.overviewReport.invoice.forEach(function (row) {
        row.supplierName = row.supplier.name;
        row.supplierCurrency = row.supplier.currency;
        row.country = row.supplier.addressCountry;
      });
      const mapper = [
        {displayText: 'Supplier Name', value: 'supplierName'},
        {displayText: 'Country', value: 'country'},
        {displayText: 'Payment Cycle', value: 'invoicePaymentCycle'},
        {displayText: 'Period', value: 'period'},
        {displayText: 'Cycle Cost', value: 'amount'},
        {displayText: 'currency', value: 'supplierCurrency'},
        {displayText: 'Cycle Cost (USD)', value: 'amountInUsd'},
        {displayText: 'Status', value: 'status'},
        {displayText: 'Start Date', value: 'startDate'},
        {displayText: 'End Date', value: 'endDate'},
      ];
      const date = moment.utc().format('DD-MM-YYYY');
      return $scope.createCSVReport(mapper, $scope.overviewReport.invoice, `overview-invoice-${date}.csv`);
    } else {
      return $rootScope.$emit('notify', {type: 'warn', title: '', message: 'No Data' });
    }
}
$scope.accruedTooltipStyle = {
  width: '400px',
  textAlign: 'center',
  borderRadius: '5px',
  padding: '5px',
  position: 'absolute',
  zIndex: 999999,
  bottom: '0', /* Position the tooltip above the icon */
  left: '10%',
  marginLeft: '20px',
  backgroundColor: 'white',
};

$scope.exportSelectedOverviewToCsv = function(paymentType) {
    if (paymentType === 'topup'){
      generateTopupOverviewCSV();
    }
    if (paymentType === 'invoice'){
      generateInvoiceOverviewCSV();
    }
};

$scope.exportAccruedToCsv = function() {
  const report = $scope.accruedReport;
  const mapper = [
    { displayText: 'Supplier Name', value: 'supplierName'},
    { displayText: 'Is Active', value: 'isActivated'},
    { displayText: 'Supplier Currency', value: 'currency'},
    { displayText: 'Cost in supplier currency', value: 'total|priceFormatToFixed'},
    { displayText: 'Cost in USD', value: 'totalInUsd|priceFormatToFixed'},
    { displayText: 'Deducted topup cost for period', value: 'totalReduced|priceFormatToFixed'},
  ]
  const fromDate = moment.utc($scope.filters.accruedReport.fromDate).format('DD.MM.YYYY');
  const toDate = moment.utc($scope.filters.accruedReport.toDate).format('DD.MM.YYYY');
  return $scope.createCSVReport(mapper, report, `Accrued supplier cost ${fromDate}-${toDate}.csv`);
}

$scope.exportInvoiceGapsToCsv = function() {
  const report = $scope.invoiceGapsReport;
  const mapper = [
    { displayText: 'Supplier Name', value: 'supplierName'},
    { displayText: 'Supplier Currency', value: 'currency'},
    { displayText: 'Invoice Amount in Supplier Currency', value: 'invoiceTotalInSupplierCurrency|priceFormatToFixed'},
    { displayText: 'Invoice Amount in USD', value: 'invoiceTotalInUsd|priceFormatToFixed'},
    { displayText: 'Bookaway Amount in Supplier Currency', value: 'bookawayTotalInSupplierCurrency|priceFormatToFixed'},
    { displayText: 'Bookaway Amount in USD', value: 'bookawayTotalInUsd|priceFormatToFixed'},
  ]
  const fromDate = moment.utc($scope.filters.invoiceGapsReport.fromDate).format('DD.MM.YYYY');
  const toDate = moment.utc($scope.filters.invoiceGapsReport.toDate).format('DD.MM.YYYY');
  return $scope.createCSVReport(mapper, report, `Invoice Gaps Report.csv`);
}

$scope.showAccruedPayments = function (supplierName, supplierId, supplierCurrency, payments, fromDate, toDate) {
  $uibModal.open({
    templateUrl: 'views/payments-report/accrued-payments-breakdown.html',
    controller: 'AccruedPaymentsBreakdownCtrl',
    resolve: {
      supplierName: [function(){ return supplierName; }],
      supplierId: [function(){ return supplierId; }],
      supplierCurrency: [function(){ return supplierCurrency; }],
      payments: [function(){ return payments; }],
      fromDate: [function(){ return fromDate; }],
      toDate: [function(){ return toDate; }],
      createCSVReport: [function(){ return $scope.createCSVReport; }],
    },
  })
  .result
  .then(function(response) {
    if (response) {
      $scope.createInvoiceReport(0, $scope.filters.invoiceReport.pageSize);
    }
  });
}

$scope.showInvoiceGapsBreakdown = function (supplierName, supplierCurrency, breakdown) {
  $uibModal.open({
    templateUrl: 'views/payments-report/invoice-gaps-breakdown.html',
    controller: 'InvoiceGapsBreakdownCtrl',
    resolve: {
      supplierName: [function(){ return supplierName; }],
      supplierCurrency: [function(){ return supplierCurrency; }],
      breakdown: [function(){ return breakdown; }],
      createCSVReport: [function(){ return $scope.createCSVReport; }],
    },
  })
  .result
  .then(function(response) {
    if (response) {
      $scope.createInvoiceReport(0, $scope.filters.invoiceReport.pageSize);
    }
  });
}

$scope.exportPaymentReportToCsv = function() {
  const report = $scope.reportData;
  const mapper = [
    { displayText: $scope.user.shouldSeeFullAdmin ? 'Booking Reference' : 'Bookaway Booking Reference', value: 'bookingReference'},
    { displayText: $scope.user.shouldSeeFullAdmin ? 'Supplier Booking Id' : 'Booking Id', value: 'supplierBookingReference' },
    { displayText: 'Booking Code', value: 'bookingCode'},
    { displayText: 'Effective Date', value: 'effectiveDate'},
    { displayText: 'Booking Date', value: 'bookingDate'},
    { displayText: 'Departure Date', value: 'departureDate'},
    { displayText: 'Route', value: 'route'},
    { displayText: 'Status', value: 'status'},
    { displayText: 'Passenger Name ', value: 'passengerName'},
    { displayText: 'Tickets', value: 'tickets'},
    { displayText: 'Cost', value: 'costInSupplierCurrency|priceFormatToFixed'},
    { displayText: 'Currency', value: 'supplierCurrency'},
    { displayText: 'Supplier Name', value: 'supplierName'},
    { displayText: 'Supplier Id', value: 'supplierId'},
    { displayText: 'Operator Name', value: 'operatorName'},
    { displayText: 'Operator Id', value: 'operatorId'},
  ];
  const fromDate = moment.utc($scope.filters.paymentReport.fromDate).format('DD.MM.YYYY');
  const toDate = moment.utc($scope.filters.paymentReport.toDate).format('DD.MM.YYYY');
  return $scope.createCSVReport(mapper, report, `payment-report-${$scope.filters.paymentReport.selectedSupplier.name} ${fromDate}-${toDate}.csv`);
};
 $scope.permissionDenied = function () {
  return $rootScope.$emit('notify', {type: 'error', title: '', message: 'Permission denied. only finance admin can perform this action.' });
 };
  $scope.addReconciliation = function() {
    if(!$scope.isFinanceAdmin) {
      return $scope.permissionDenied();
    }
    // TODO:
  };

  function trimFunction (x){
      return x.trim();
  }

  function transformToCamelCase (x){
      return _.camelCase(x);
  }

  // this helper function help to determin how to display the cycle in the report
  function isBiWeekly(cycle) {
    return (moment(cycle.end).date() === 15) || (moment(cycle.start).date() === 16)
  }

  $scope.uploadPaymentsWithCSV = function (event) {
    if(!$scope.isFinanceAdmin) {
      return $scope.permissionDenied();
    }
    let fileSelected = event.target.files[0];
    let fileExt = fileSelected.name.split('.')[1];

    if (fileExt !== 'csv') {
      return $rootScope.$emit('notify', {type: 'error', title: '', message: 'File is not supported.' });
    }

    let fileReader = new FileReader();

    fileReader.onloadend = function(e) {
      let data = e.target.result;
      const parsedData = Papa.parse(data, { header: true, transform: trimFunction, skipEmptyLines: true, transformHeader: transformToCamelCase });
      const validateRes = validatePaymentsFromCSV(parsedData);
        let body = {
            payments: validateRes.rows,
        };
      if (validateRes.isValid) {
          InventoryRestangular.one('v2/supplier')
              .all(`upload-payments`).post(body)
              .then(function (response){
                  if (response.success) {
                      return $rootScope.$emit('notify', {type: 'success', title: '', message: 'Payments Uploaded successfully'});
                  }
                  let error = 'Error occurred, Please try again after revalidating the file.';
                  if (response.error) {
                      error = response.error;
                  }
                  $uibModal
                      .open({
                          templateUrl: 'views/are-you-sure-modal.html',
                          controller: 'AreYouSureCtrl',
                          resolve: {
                              text: [function () { return `Error is: \n ${error} \n\n Do you want to try again, without amount validation?`; }],
                              title: [function () { return 'Error in upload Payments'; }]
                          }
                      })
                      .result
                      .then(function(response) {
                          if (response) {
                              body.validateAmounts = false;
                              InventoryRestangular.one('v2/supplier')
                                  .all(`upload-payments`).post(body)
                                  .then(function (response){
                                      if (response.success) {
                                          return $rootScope.$emit('notify', {type: 'success', title: '', message: 'Payments Uploaded successfully'});
                                      }
                                      let error = 'Error occurred, Please try again after revalidating the file.';
                                      if (response.error) {
                                          error = response.error;
                                      }
                                      $uibModal.open({
                                          templateUrl: 'views/error-messages.html',
                                          controller: 'ErrorMessagesCtrl',
                                          resolve: {
                                              errorMessages: function () {
                                                  return [error];
                                              }
                                          }
                                      });
                                  })
                                  .catch(function (serverResponse) {
                                      return $rootScope.$emit('notify', {type: 'error', title: 'Error', message: serverResponse.data.message});
                                  });
                          }
                      });
              })
              .catch(function (serverResponse) {
                  return $rootScope.$emit('notify', {type: 'error', title: 'Error', message: serverResponse.data.message});
           });
      }
      event.target.value = '';
    };

    fileReader.readAsBinaryString(fileSelected);
  };

  $scope.fetchData = function(limit, skip) {
    let query = '';
    if ($scope.filters.paymentReport.searchBy === 'dates') {
      const selectedSupplier = _.get($scope, 'filters.paymentReport.selectedSupplier');
      if (!selectedSupplier) {
        return $rootScope.$emit('notify', {type: 'error', title: '', message: 'Please select a supplier' });
      }
      const fromDateRaw = moment($scope.filters.paymentReport.fromDate);
      const toDateRaw = moment($scope.filters.paymentReport.toDate);
      if (!fromDateRaw.isValid() || !toDateRaw.isValid()) {
        return $rootScope.$emit('notify', {type: 'error', title: '', message: 'Please enter valid dates' });
      }
      const fromDate =  fromDateRaw.format('YYYY-MM-DD');
      const toDate =  toDateRaw.format('YYYY-MM-DD');
      query = `supplierId=${selectedSupplier.id}&fromDate=${fromDate}&toDate=${toDate}&offset=${skip}&limit=${limit}&bookingReference=`;
    } else {
      if ($scope.filters.paymentReport.bookingReference.length === 0) {
        return $rootScope.$emit('notify', {type: 'error', title: '', message: 'Please fill in booking reference' });
      }
      query = `bookingReference=${$scope.filters.paymentReport.bookingReference}&offset=${skip}&limit=${limit}&supplierId=&fromDate=&toDate=`;
    }
    $scope.isLoading = true;

    InventoryRestangular.one('v2/supplier')
    .all(`supplier-payments-report?${query}`).get('')
      .then(function (supplierPayments) {
        if(!$scope.reportData) {
          $scope.reportData = [];
        }
        $scope.reportData = $scope.reportData.concat(supplierPayments.data);
        supplierPayments.totalSumsByCurrency.forEach(function(t) {
          const entry =  $scope.reportTotals.find(function(reportTotal) { return reportTotal.currency === t.currency; });
          if (entry) {
            entry.total += t.total;
          } else {
            $scope.reportTotals.push(t);
          }
        });
        $scope.totalItems = $scope.reportData.length;
        if (skip === 0) {
          $scope.pageChanged();
        }
        if (supplierPayments.total > (skip + limit)) {
          $scope.fetchData(limit, skip + limit);
        } else {
          $scope.isLoading = false;
        }
      }).catch($scope.handleError);
  };
  $scope.createReport = function() {
    $scope.clearPaymentReportData();
    $scope.fetchData($scope.filters.paymentReport.pageSize, 0);
    paymentsReportService.currentPage = 1;
  };

  $scope.showTransactionsByDates = function(startDate, endDate, supplier) {
    const startDateUtc = moment.utc(startDate).startOf('day').toDate();
    const endDateUtc = moment.utc(endDate).startOf('day').toDate();
    $scope.clearPaymentReportData();
    $scope.filters.paymentReport.selectedSupplier = $scope.filters.supplierSpecificReport.selectedSupplier;
    $scope.filters.paymentReport.fromDate = startDateUtc;
    $scope.filters.paymentReport.toDate = endDateUtc;
    if (supplier) {
      $scope.filters.paymentReport.selectedSupplier = supplier;
    }
    $scope.fetchData($scope.filters.paymentReport.pageSize, 0);
    $scope.activeTab = 1;
    paymentsReportService.activeTab = 1;
    paymentsReportService.currentPage = 1;
  };
  $scope.clearInvoiceReport = function() {
    $scope.invoiceReportData = [];
    $scope.filters.invoiceReport.filteredInvoiceReportData = [];
    $scope.currInvoiceReportPageItems = [];
    $scope.isInvoiceReportLoading = false;
    $scope.filters.invoiceReport.showMyInvoices = true;
    $scope.filters.invoiceReport.selectedGapLevel = { label: 'All', value: null };
    $scope.filters.invoiceReport.selectedRegion = { label: 'All', value: null };
    $scope.filters.invoiceReport.selectedAssignee = [];
    $scope.filters.invoiceReport.selectedStatus = [{id: 'awaiting_approval'}, {id: 'invoice_approved'}, {id: 'paid'}, {id: 'open'}, {id: 'payment_canceled'}];
    const query = {additionalScopes: ['cco', 'finance-ops', 'supply-manager', 'finance-admin']};
    UsersRestangular
      .all('users')
      .getList(query)
      .then(function(assignees) {
        $scope.filters.invoiceReport.assignees = assignees.map((assignee) => ({
          label: assignee.displayName,
          id: assignee._id,
        }));
      });
    $scope.filters.invoiceReport.sortOption = {label: 'Due Date, latest first', value: 'dueDateLatest'};
    $scope.filters.invoiceReport.months = [];

    InventoryRestangular.one('v2/supplier')
    .all(`supplier-invoice-report/periods`).get('')
    .then(function (periodsResponse) {
        $scope.filters.invoiceReport.months = periodsResponse;
    });
    $scope.filters.invoiceReport.selectedMonths = [];
  };
  $scope.convertInvoiceStatus = function (status) {
    switch(status) {
      case 'open': return 'Open';
      case 'invoice_rejected': return 'Rejected';
      case 'invoice_approved': return 'Approved';
      case 'invoice_received': return 'Received';
      case 'awaiting_approval': return 'Awaiting Approval';
      case 'paid': return 'Paid';
      case 'payment_canceled': return 'Payment canceled';
      default: return status;
    }
  };
  $scope.allowActionOnInvoice = function(invoice) {
    return ($scope.isFinanceAdmin || invoice.isAssignee || (invoice.assignee.role === 'supply-manager' && $scope.isSupplyManager)) &&
      (invoice.status === 'awaiting_approval');
  };
  $scope.allowCancelPaymentAction = function(invoice) {
    return $scope.isFinanceAdmin && invoice.status === 'paid';
  };
  $scope.createInvoiceReport = function(skip, limit) {
    $scope.isInvoiceReportLoading = true;
    const filter = {};
    if ($scope.filters.invoiceReport.selectedMonths && $scope.filters.invoiceReport.selectedMonths.length > 0) {
      filter.periods = { includePeriods: $scope.filters.invoiceReport.selectedMonths};
    }
    if ($scope.filters.invoiceReport.selectedAssignee && $scope.filters.invoiceReport.selectedAssignee.length > 0) {
      const selectedAssignee = $scope.filters.invoiceReport.selectedAssignee.map((obj) => obj.id);
      filter.assignee = { includeAssignee: selectedAssignee};
    }
    if ($scope.filters.invoiceReport.selectedRegion && $scope.filters.invoiceReport.selectedRegion.value) {
      filter.region = $scope.filters.invoiceReport.selectedRegion.value;
    }
    if ($scope.filters.invoiceReport.selectedSupplier && $scope.filters.invoiceReport.selectedSupplier._id) {
      filter.supplierId = $scope.filters.invoiceReport.selectedSupplier._id;
    }
    if ($scope.filters.invoiceReport.selectedStatus && $scope.filters.invoiceReport.selectedStatus.length > 0) {
      const selectedStatus = $scope.filters.invoiceReport.selectedStatus.map((obj) => obj.id);
      filter.status = { includeStatus: selectedStatus};
    }
    if ($scope.filters.invoiceReport.selectedGapLevel && $scope.filters.invoiceReport.selectedGapLevel.value) {
      filter.gapLevel = $scope.filters.invoiceReport.selectedGapLevel.value;
    }

    InventoryRestangular.one('v2/supplier')
    .all(`supplier-invoice-report?offset=${skip}&limit=${limit}`).getList(filter)
      .then(function (supplierInvoicesRes) {
        const supplierInvoices = supplierInvoicesRes;
        const invoices = supplierInvoices.map((invoice) => {
          invoice.periods = [];
          if (invoice.invoiceType === 'tax_bill') {
            invoice.invoiceTypeDisplay = 'Tax Bill';
          }
          else if (invoice.invoiceType ===  'topup_tax_bill'){
            invoice.invoiceTypeDisplay = 'TopUp Tax Bill';
          }
          else if (invoice.invoiceType ===  'prepaid_inventory'){
            invoice.invoiceTypeDisplay = 'Prepaid';
          }
          invoice.statusDisplay = $scope.convertInvoiceStatus(invoice.status);
          if(invoice.invoiceType !== 'prepaid_inventory') {
            if (invoice.invoiceType === 'tax_bill' && invoice.invoiceCycles) {
              invoice.invoiceCycles.forEach(function(cycle) {
                let period = {
                  label: moment(cycle.start).format('MMM YYYY'),
                  start: cycle.start,
                  end: cycle.end,
                };
                if (isBiWeekly(cycle)) {
                  period.label = `${moment.utc(cycle.start).date()}-${moment.utc(cycle.end).date()} ${moment.utc(cycle.start).format('MMM')} ${moment.utc(cycle.end).format('YYYY')}`;
                }
                invoice.periods.push(period);
              });
            }
            else {
              let dateRangePeriod = {
                label: `${moment(invoice.invoiceDateRange.start).format('DD/MM/YYYY')}-${moment(invoice.invoiceDateRange.end).format('DD/MM/YYYY')}`,
                start: invoice.invoiceDateRange.start,
                end: invoice.invoiceDateRange.end,
              };
              invoice.periods.push(dateRangePeriod);
            }
          }
          invoice.createdAt = moment(invoice.createdAt). format('DD/MM/YYYY');
          invoice.updatedAt = moment(invoice.updatedAt). format('DD/MM/YYYY');
          invoice.gapPercent = Math.abs(100 - (invoice.invoiceAmountUsd/invoice.bwAmountUsd*100));
          let extInvoice = {};
          Object.assign(extInvoice, invoice);
          extInvoice.isAssignee = invoice.assignee && invoice.assignee.userId.toString() === user._id.toString();
          extInvoice.dueDate = moment(extInvoice.dueDate).format('DD/MM/YYYY');
          return extInvoice;
        });
        $scope.invoiceReportData = $scope.invoiceReportData.concat(invoices);
        $scope.filters.invoiceReport.filteredInvoiceReportData = $scope.filters.invoiceReport.filteredInvoiceReportData.concat(invoices);
        if (skip === 0) {
          $scope.currInvoiceReportPageItems = invoices;
        }
        if (supplierInvoicesRes.meta.total > (skip + limit)) {
          $scope.createInvoiceReport(skip + limit, limit);
        } else {
          $scope.isInvoiceReportLoading = false;
          $scope.isInvoicesFetchStarted = false;
          $scope.invoiceReportData = $scope.invoiceReportData.sort((a, b) => {
            // thr report is already sorted by dueDate but we will always display awaiting approval fist
            if (b.status === 'awaiting_approval') { return 1; }
            return -1;
          });
          $scope.filters.invoiceReport.filteredInvoiceReportData = $scope.invoiceReportData;
          $scope.invoiceReportPageChanged();
        }
      });
  };

  $scope.cancelPaymentsForInvoice = function(invoice) {
    InventoryRestangular.all(`v2/supplier/${invoice._id}/cancel-payments`)
    .post({ paymentType: invoice.supplier.paymentType })
    .then(function (response) {
      if (response.success) {
        $rootScope.$emit('notify', {type: 'success', title: 'Success', message: 'Operation Succeeded.'});
        $scope.createInvoiceReport(0, $scope.filters.invoiceReport.pageSize);
        return response;
      } else {
        const message = response && response.error ? response.error : 'Server Error Occurred.';
        $rootScope.$emit('notify', {type: 'error', title: 'Error', message});
      }
    }).catch(function(err) {
      $rootScope.$emit('notify', {type: 'error', title: 'Error', message: 'Error Occurred. Please try again.'});
    });
  };

  $scope.$watch('paymentsReportService.activeTab', function(newVal) {
    if(newVal === 2){
      if (user.shouldSeeFullAdmin) {
        $scope.clearInvoiceReport();
      }
    }
  });

  $scope.canCancelDepositRequest = function(depositRequest) {
    return depositRequest.type === 'deposit_request' &&
           !depositRequest.isCancelled &&
           !$scope.supplierTopupReport.report.find(reportItem => reportItem.type === 'deposit' && reportItem.invoiceReference === depositRequest.invoiceReference);
  }

  $scope.cancelDepositRequest = function(data) {
    $uibModal
    .open({
      templateUrl: 'views/are-you-sure-modal.html',
      controller: 'AreYouSureCtrl',
      resolve: {
        text: null,
        title: [function () { return 'Are you sure?'; }]
      }
    })
    .result
    .then(function(response) {
      if (response) {
        InventoryRestangular.one(`v2/supplier/`).all(`${data.supplierId}/topup/update`)
        .post({ supplierId: data.supplierId, depositId: data.depositId, isCancelled: true, note: 'Cancelled request - ' + data.note })
        .then(function(deposit) {
          if (deposit.isCancelled) {
            $scope.$emit('notify', {type: 'success', title: 'Success', message: 'Cancelled deposit request successfully'});
          } else {
            $rootScope.$emit('error', {type: 'error', title: 'Error', message: 'Error occurred while canelling the deposit request'});
          }
        });
      }
    });
  }

  $scope.topupDepositModal = function(data) {
    if((data.type === 'adjustment' && !$scope.isFinanceAdmin) || !$scope.allowDepositRequest) {
      return $scope.permissionDenied();
    }
    $uibModal.open({
      templateUrl: 'views/payments-report/add-topup-modal.html',
      controller: 'AddTopupDepositCtrl',
      resolve: {
        type: [function(){ return data.type; }],
        supplierId: [function(){ return data.supplierId; }],
        supplierName: [function(){ return data.supplierName; }],
        currency: [function(){ return data.currency; }],
        depositId: [function(){ return data.depositId; }],
        calculationBasedOn: [function(){ return data.calculationBasedOn; }],
        invoicePaymentCycle: [function(){ return data.invoicePaymentCycle; }],
        last14DaysAvg: [function(){ return data.last14DaysAvg; }],
      },
    })
    .result
    .then(function(response) {});
  };

  $scope.uploadInvoice = function(supplier) {
    // if(!$scope.isFinanceAdmin) {
    //   return $scope.permissionDenied();
    // }
    $uibModal.open({
      templateUrl: 'views/payments-report/upload-invoice.html',
      controller: 'UploadInvoiceCtrl',
      resolve: {
        supplier: [function(){ return supplier; }],
        invoicePeriods: [function() { return $scope.invoicePeriods; }],
        user: [function() { return $scope.user; }]
      },
    })
    .result
    .then(function(response) {
      if (response) {
        $scope.createInvoiceReport(0, $scope.filters.invoiceReport.pageSize);
      }
    });
  };
  $scope.openInvoiceNote = function(title, note, invoiceId, hideAddingNote = false) {
    $uibModal.open({
      templateUrl: 'views/payments-report/note.html',
      controller: 'NoteCtrl',
      resolve: {
        title: [function(){ return title; }],
        note: [function() { return note; }],
        hideAddingNote: [function() { return hideAddingNote; }],
        onAddNote: [function() {
          return function(notes) {
            return InventoryRestangular.one('v2/supplier')
              .all(`invoice/${invoiceId}/action`).post({
                action: 'addNote',
                assignedBy: {
                  name: user.displayName,
                  role: $scope.role,
                },
                assignee: null,
                notes,
              })
              .then(function (response) {
                if (response.success) {
                  const selectedInvoice = $scope.filters.invoiceReport.filteredInvoiceReportData.find(invoice => invoice._id === invoiceId);
                  if (selectedInvoice) {
                    selectedInvoice.note = response.invoice.note;
                  }
                  $rootScope.$emit('notify', {type: 'success', title: 'Success', message: 'Operation Succeeded.'});
                  return response;
                } else {
                  const message = response.data && response.data.error ? response.data.error : 'Server Error Occurred.';
                  $rootScope.$emit('notify', {type: 'error', title: 'Error', message});
                }
              }).catch(function(){
                $rootScope.$emit('notify', {type: 'error', title: 'Error', message: 'Error Occurred. Please try again later.'});
              });
          };
        }]
      },
    })
    .result
    .then(function(response) {

    });
  };
  $scope.fetchInvoiceFileError = function () {
    $rootScope.$emit('notify', {type: 'error', title: 'Error', message: 'Error fetching invoice file'});
  };
  $scope.blobConversion = function (b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];
    for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  $scope.fetchInvoiceFile = function(invoice) {
    InventoryRestangular.one('v2/supplier')
    .all(`${invoice._id.toString()}/file`).get('')
      .then(function (fileResp) {
        if (fileResp.success) {
          const type = invoice.fileExtension === 'pdf' ? 'application/pdf' : 'image/png';
          const blobURL = URL.createObjectURL($scope.blobConversion(fileResp.file, type));
          const newWindow = window.open(blobURL);
          const theScript = document.createElement('script');
          theScript.innerHTML = 'window.onload = $;';
          newWindow.document.body.appendChild(theScript);
        } else {
          $scope.fetchInvoiceFileError();
        }
      }).catch($scope.fetchInvoiceFileError);
  };
  $scope.invoiceAction = function(action, invoice) {
    $uibModal.open({
      templateUrl: 'views/payments-report/invoice-action.html',
      controller: 'InvoiceActionCtrl',
      resolve: {
        action: [function(){ return action; }],
        invoice: [function() { return invoice; }],
        user: [function() { return $scope.user; }]
      },
    })
    .result
    .then(function(response) {
      if (response) {
        $scope.createInvoiceReport(0, $scope.filters.invoiceReport.pageSize);
      }
    });
  };
  $scope.cancelPayments = function (invoice) {
    $uibModal
      .open({
        templateUrl: "views/are-you-sure-modal.html",
        controller: "AreYouSureCtrl",
        resolve: {
          text: [
            function () {
              return null;
            },
          ],
          title: [
            function () {
              return 'Payments Cancellation';
            },
          ],
        },
      })
      .result.then(function (response) {
        if (response) {
          $scope.cancelPaymentsForInvoice(invoice);
        }
      });
  };
  $scope.pageChanged = function() {
    const page = paymentsReportService.currentPage;
    const startIndex = ((page - 1) * $scope.filters.paymentReport.pageSize);
    let endIndex = startIndex + $scope.filters.paymentReport.pageSize;
    if (endIndex > $scope.reportData.length) {
      endIndex = $scope.reportData.length;
    }
    $scope.currPageItems = $scope.reportData.slice(startIndex, endIndex);
  };

  $scope.invoiceReportPageChanged = function() {
    const page = paymentsReportService.invoiceReportDataCurrPage;
    const startIndex = ((page - 1) * $scope.filters.invoiceReport.pageSize);
    let endIndex = startIndex + $scope.filters.invoiceReport.pageSize;
    if (endIndex > $scope.filters.invoiceReport.filteredInvoiceReportData.length) {
      endIndex = $scope.filters.invoiceReport.filteredInvoiceReportData.length;
    }
    $scope.currInvoiceReportPageItems = $scope.filters.invoiceReport.filteredInvoiceReportData.slice(startIndex, endIndex);
  };


  $scope.topupReportPageChanged = function() {
    const page = paymentsReportService.topupReportCurrPage;
    const startIndex = ((page - 1) * $scope.filters.supplierSpecificReport.topupPageSize);
    let endIndex = startIndex + $scope.filters.supplierSpecificReport.topupPageSize;
    if (endIndex > $scope.filters.supplierSpecificReport.filteredSupplierTopupData.length) {
      endIndex = $scope.filters.supplierSpecificReport.filteredSupplierTopupData.length;
    }
    $scope.currTopupReportPageItems = $scope.filters.supplierSpecificReport.filteredSupplierTopupData.slice(startIndex, endIndex);
  };



  $scope.handleError = function(err) {
    $scope.isLoading = false;
    var message = '';
    if (_.isArray(err)) {
      message = err.join('<br>');
    } else {
      message = _.get(err, 'data.message') || err.message || '';
    }
    console.error('failed operation with error: \n' + message.replace(/<br>/g, '\n'));
    $rootScope.$emit('notify', {type: 'error', title: 'Error', message: message});
  };
  $scope.performValidation = function(objectToCheck) {
    const errors = [];
    var valid = true;
    Object.keys(objectToCheck).forEach(function (key) {
      var item = objectToCheck[key];
      if (!item && item !== 0) {
        errors.push(`Error: ${key} is required`);
        valid = false;
      }
    });
    return {valid: valid, errors: errors};
  };

  $scope.showBooking = function(bookingId) {
    $scope.BookingViewModal.show(bookingId, user);

    var search = $scope.location.search();
    search.booking = bookingId;
    $scope.location.search(search);
  };

  $scope.setOverviewReportPaymentType = function(paymentType) {
    $scope.filters.overview.paymentType = paymentType;
  };

  angular.element("#invoiceFileChooser").on("change", function(evt) {
    if (evt) {
       if (evt.target.files && evt.target.files.length > 0) {
          vm.fileName =  evt.target.files[0].name;
          var fd = new FormData();
          fd.append('fileMetadata', JSON.stringify(imgMetadata));
          fd.append('file', fileToUpload);
       }
    }
 });
 $scope.clearPaymentReportData = function() {
  $scope.reportData = [];
  $scope.reportTotals = [];
  $scope.totalItems = 0;
  $scope.currPageItems = [];
 };

 $scope.setInvoiceForUpdate = function (invoiceId) {
   $scope.invoicePaymentCycleIdForFileUpload = invoiceId;
 };

 $scope.searchByFilter = function() {
    if ($scope.isInvoicesFetchStarted === false) {
      $scope.invoiceReportData = [];
      $scope.filters.invoiceReport.filteredInvoiceReportData = [];
      $scope.currInvoiceReportPageItems = [];
      $scope.isInvoicesFetchStarted = true;
      $scope.createInvoiceReport(0, $scope.filters.invoiceReport.pageSize);
    }
};

$scope.clearSelectedFilter = function() {
  if ($scope.isInvoicesFetchStarted === false) {
    $scope.filters.invoiceReport.selectedRegion = { label: 'All', value: null };
    $scope.filters.invoiceReport.selectedStatus = $scope.invoiceStatusData.filter(status => status.id !== 'invoice_rejected');
    $scope.filters.invoiceReport.selectedGapLevel = { label: 'All', value: null };
    $scope.filters.invoiceReport.selectedMonths = [];
    $scope.filters.invoiceReport.selectedAssignee = [];
    $scope.filters.invoiceReport.selectedSupplier = null;
  }
};
$scope.fetchInvoiceGapsReport = function() {
  $scope.fetchingInvoiceGapsReport = true;
  const fromDate = moment($scope.filters.invoiceGapsReport.fromDate).startOf('day').format('YYYY-MM-DD');
  const toDate = moment($scope.filters.invoiceGapsReport.toDate).endOf('day').format('YYYY-MM-DD');
  InventoryRestangular.one('v2/supplier')
  .all(`invoice-gaps-report?fromDate=${fromDate}&toDate=${toDate}&useCache=false`).get('')
  .then(function(invoiceGapsReport) {
    $scope.invoiceGapsReport = invoiceGapsReport;
    $scope.filteredInvoiceGapsReport = invoiceGapsReport;
  }).finally(function() {
    $scope.fetchingInvoiceGapsReport = false;
  })
}
 $scope.fetchInvoiceGapsReport();
}

'use strict';

function paymentsReportProvider() {
  this.$get = [function () {
    var paymentsReportService = {
      activeTab: user.shouldSeeFullAdmin ? 0 : 1,
      currentPage: 1,
      overviewInvoiceCurrPage: 1,
      invoiceReportDataCurrPage: 1,
      topupReportCurrPage: 1,
    };
    return paymentsReportService;
  }];
}
